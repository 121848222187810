<template>
    <div class="find_advertising">
        <div class="search_box" id="top">
            <div class="input_box">
                <div class="input_shadow">
                    <el-input
                            type="text"
                            ref="input"
                            v-model="search.keywords"
                            @keyup.enter.native="handleSearch"
                            @input="closeDialog"
                            @focus="showHistory"
                            @blur="hiddenHistory"
                            placeholder="请输入推广关键词"
                            maxlength="100">
                        <template #append style="width: 48px">
                            <div class="box" @click="handleSearch">
                                <img src="@/assets/img/search/icon_search.png" alt="" width="18px" height="18px">
                            </div>
                        </template>
                    </el-input>

                <div class="empty_input" v-if="showEmptyInput">
                    <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
                    <span>你还未输入关键词</span>
                </div>

                <div class="search_history" v-if="showInputHistory">
                    <div class="search_item" v-for="(item,i) in searchHistoryList" :key="i" @click="selectHistory(item)">{{item}}</div>
                </div>
            </div>
            <div class="check_box">
                <span class="tip">
                    <el-checkbox v-model="search.query_type" :true-label="1" :false-label="2" @change="changeSort">精准匹配关键词</el-checkbox>
                    <img src="@/assets/img/search/icon_more.png" alt="" @mouseover="showMoreDialog=true" @mouseleave="showMoreDialog=false">
                </span>

                <div class="more_info" v-if="showMoreDialog">
                    <div class="test_triangle_border">
                        <div class="popup">
                            <span><em></em></span>
                            根据输入关键词精准匹配搜索结果，挖掘关键词搜索广告创意。
                        </div>
                    </div>
                </div>
            </div>

            </div>
            <div class="select_line">
                <span class="select_label">推广平台：</span>
                <platformSelector ref="platformSelector" @childChange="platSelectSelect" :list="[1,2,3,4,5,6,7]"></platformSelector>
            </div>
        </div>
        <div class="search_list" v-loading="listLoading">
            <div class="list_head">
                <span class="result">共找到<span class="count">{{listData.real_cnt > 100000? '100000+':listData.real_cnt}}</span>个广告创意</span>
                <div class="common"></div>
                <myButton :type="hadUpdate?'secondary':'default'" width="100" height="33" @click="update" :style="{'right': $store.state.userInfo.has_export_permission?'300px':'130px'}" class="update" :disable="hadUpdate">
                    <img slot="img" v-if="!hadUpdate" :style="'transform: rotate('+ angle +'deg)'" src="@/assets/img/companyDetails/refresh.png" alt="" width="14" height="14">
                    <img slot="img" v-else style="transform: rotate(360deg);" src="@/assets/img/companyDetails/unrefresh.png" alt="" width="14" height="14">
                    <span slot="text">更新数据</span>
                </myButton>
                <myButton v-if="$store.state.userInfo.has_export_permission" type="default" width="158" height="33" @click="exportTel" class="button"  style="right: 130px;">
                    <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">导出企业联系电话</span>
                </myButton>
                <myButton type="default" width="100" height="33" @click="exportList" class="button" v-if="listConfig.total">
                    <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">立即导出</span>
                </myButton>
                <myButton type="secondary" width="100" height="33" class="button" :disable="true" v-else>
                    <img slot="img" src="@/assets/img/monitor/newest/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">立即导出</span>
                </myButton>
            </div>
            <div class="list_body">
                <el-table
                        :data="listData.rows"
                        @sort-change="sortChange"
                        :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                    <el-table-column
                            width="150"
                            show-overflow-tooltip
                            label="关键词">
                        <template slot-scope="scope">
                            <span v-if="scope.row.h_ad_word !== ''" v-html="scope.row.h_ad_word.v[0]" @click="reload(scope.row.h_ad_word.v[0])"></span>
                            <span class="canClick" v-if="scope.row.h_ad_word == ''" @click="reload(scope.row.ad_word)">{{scope.row.ad_word}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="100"
                            label="推广平台">
                        <template slot-scope="scope">
                            <span>{{$C.platformTurn(scope.row.platform)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        label="创意标题">
                        <template slot-scope="scope">
                            <a rel="noopener noreferrer" :href="scope.row.land_url"  target="_blank" v-html="scope.row.title" @click="handleFire(3151)"></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        label="创意描述">
                        <template slot-scope="scope">
                            <span v-if="scope.row.content">{{scope.row.content}}</span>
                            <span v-else>暂无数据</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="150"
                            label="推广域名">
                        <template slot-scope="scope">
                            <span>{{scope.row.top_domain}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            min-width="100"
                            label="企业名称">
                        <template slot-scope="scope">
                            <span class="canClick" type="text" @click="toCompany(scope.row.company_name_digest)">{{scope.row.company_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="150"
                            prop="ad_time_from"
                            sortable="custom"
                            label="最后推广时间">
                    </el-table-column>
                    <el-table-column
                            width="150"
                            label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="!scope.row.monitor" @click="handelMonitor(scope.row.ad_word,2)">监测关键词</el-button>
                            <el-button type="text" disabled  v-if="scope.row.monitor">监测中</el-button>
                            <el-button type="text"  v-if="scope.row.monitor" @click="cancelMonitor(scope.row.ad_word,2)">取消</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <div class="empty_box" v-if="$store.state.userInfo.vip_type != 5">
                            <img src="../../../assets/img/compare/searching.png" height="145" width="188"/>
                            <span>实时更新中，请点击“更新数据”耐心等待</span>
                        </div>
                        <no-data v-else title="暂无数据"></no-data>
                    </template>
                </el-table>
                <page :config="listConfig" @pageChange="pageChange" @limitChange="limitChange"></page>
            </div>
        </div>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>

        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="handleExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="10000"
            :total-limit="100000"
            width="60%">
        </export_dialog>

        <el-dialog
            custom-class="telephone_dialog"
            title="导出数据"
            :visible.sync="telDialog">
            <div class="line1">
                <span>数据量：</span>
                <span style="color: #1f81f8">{{exportCntTel}}</span>条
            </div>
            <div class="time">
                <div style="margin-bottom: 10px">按公司名去重后，导出数据量可能偏小，以实际导出数量为准</div>
                <div>今日还可以免费导出<span style="color: #ff8840;">{{surplusTel}}</span>次</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <my-button @click="telDialog = false" type="secondary" width="80" height="33" style="margin-right: 11px">
                    <span slot="text">取消</span>
                </my-button>
                <my-button @click="handelExportTel" type="primary" width="80" height="33">
                    <span slot="text">确定</span>
                </my-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import platformSelector from "../../../components/platformSelector/platformSelector";
import searchOption from "./searchOption";
import myButton from "../../../components/myButton/myButton";
import * as api from "@/api/search"
import common_dialog from "../../../components/dialogs/common_dialog";
import export_dialog from "../../../components/dialogs/export_dialog";
import PinganAna from "pingansec-vue-ana";
import store from "../../../store";

  export default {
    name: "findAdvertising",
    mixins: [searchOption],
    components: {
      platformSelector,
      myButton,
      common_dialog,
      export_dialog
    },
    data() {
      return {
        showEmptyInput: false,
        showInputHistory: false,
        searchHistoryList: [],
        timer: null,
        hadUpdate: false, //刷新按钮
        angle: 0, //旋转角度
        search: {
          keywords: '',
          // 推广平台
          s_platform: [],
          query_type: 2,
          sort: 2,
        },
        listData: {},
        listConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        listLoading: false,
        showMoreDialog: false,
        notVipShow: false, //非vip导出提示弹窗
        commonDialogText: '', //弹窗提示内容
        dialogVisible: false, //导出列表确认弹窗
        surplus: null, //剩余导出次数
        exportCnt: null, //导出数量
        surplusTel: '',
        exportCntTel: '',
        telDialog: false,
      }
    },
    created() {
      PinganAna.fire(3141)
      if(this.$route.query.word) {
        this.search.keywords = this.$route.query.word
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      exportTel() {
        api.get_last_export_cnt().then(res => {
          if(res.data.result_code==0) {
            this.surplusTel = res.data.data.total-res.data.data.used;
            this.exportCntTel = this.listConfig.real_cnt > 1000?1000:this.listConfig.real_cnt
            this.telDialog = true
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelExportTel() {
        let params = {
          export_type: '13',
          export_limit: this.exportCntTel,
          export_condition: JSON.stringify({
            ...this.search,
            page: this.listConfig.page,
            limit: this.listConfig.limit
          })
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.telDialog = false
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handleFire(point) {
        PinganAna.fire(point)
      },
      update() {
        PinganAna.fire(3147)
        let search_word = this.search.keywords || ''
        PinganAna.ready(() => {
          PinganAna.userClickLog(
            '更新数据',
            '{"site":"找竞品-按广告找竞品","当前内容":"'+search_word+'"}',
            '【AI竞投】系统',
            this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
            '--',
            this.$store.state.userInfo.account
          )
        })
        this.angle += 360;
        //给动画展示时间
        setTimeout(() => {
          this.hadUpdate = true;
          this.$message({
            type: 'success',
            message: '已收到更新请求，AI竞投将尽快为您更新数据!'
          })
          let params = {
            type: 'word',
            str: search_word
          }
          api.ge_refresh_task(params).then(res => {
            if(res.data.result_code == 0) {
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        },600)
      },
      sortChange(column) {
        if(column.order=='ascending') {
          this.search.sort = 3
        } else {
          this.search.sort = 2
        }
        this.init();
      },
      handleSearch() {
        if(!this.search.keywords) {
          this.showEmptyInput = true;
          this.time = setTimeout(() => {
            this.showEmptyInput = false;
          }, 3000)
          return
        }
        this.searchHistoryList.unshift(this.search.keywords)
        localStorage.setItem('search_history_ad', JSON.stringify(this.searchHistoryList))
        this.showInputHistory = false
        this.clearSelection()
      },
      clearSelection() {
        this.$refs.platformSelector.clear()
        this.search.s_platform = []
        this.search.sort = 2
        this.listConfig.page = 1;
        this.listConfig.limit = 10;
        this.init()
      },
      closeDialog(word) {
        if(word) {
          this.showEmptyInput = false;
        }
      },
      showHistory() {
        if(!this.search.keywords) {
          this.searchHistoryList = Array.from(new Set(JSON.parse(localStorage.getItem('search_history_ad')))).slice(0,5)
          this.showInputHistory = true
        }
      },
      hiddenHistory() {
        setTimeout(() => {
          this.showInputHistory = false
        },500)
      },
      selectHistory(word) {
        this.search.keywords = word;
        this.showInputHistory = false
        this.handleSearch();
      },
      changeSort(sort) {
        this.search.query_type = sort;
        this.init();
      },
      pageChange(val) {
        this.listConfig.page = val
        this.init()
        document.getElementById("top").scrollIntoView()
      },
      limitChange(val) {
        this.listConfig.page = 1;
        this.listConfig.limit = val;
        this.init()
        document.getElementById("top").scrollIntoView()
      },
      handelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.add_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '监测成功！还可监测' +res.data.data.monitor_company_last +
                '家竞品公司，'+res.data.data.monitor_word_last+'个关键词'
            })
            PinganAna.fire(3152)
            this.init()
          } else {
            if(res.data.result_code == 120011 && (this.$store.state.userInfo.vip_type == 20 || this.$store.state.userInfo.vip_type == 10)) {
              this.commonDialogText = '您的监测数量已达上限，至尊版可监测50家竞品/100个关键词，如需监测更多，请联系您的客户经理开通'
              this.notVipShow = true;
            } else if(res.data.result_code == 120011 && this.$store.state.userInfo.vip_type == 5) {
              this.commonDialogText = '监测已达上限。标准版可监测竞品公司10家/关键词20个，请联系在线客服开通'
              this.notVipShow = true;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        })
      },
      cancelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.cancel_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '已取消监测！'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      reload(dom) {
        let del = dom.replace(/<\/?[^>]*>/g,'');
        Object.assign(this.$data, this.$options.data());
        this.search.keywords = del
        this.init();
      },
      goLink(href) {
        window.open(href, '_blank');
      },
      toCompany(digest) {
        const { href } = this.$router.resolve({
          name: "companyDetails",
          query: {
            digest
          }
        });
        window.open(href, '_blank');
      },
      exportList() {
        PinganAna.fire(3148)
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
          this.commonDialogText = '当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通';
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.listConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        let params = {
          export_type: '1',
          export_limit: count,
          export_condition: JSON.stringify(this.search)
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            PinganAna.fire(3149)
            PinganAna.ready(() => {
              PinganAna.userClickLog(
                '数据导出',
                '{"exportType":"找竞品-按广告找竞品","exportTitle":"'+this.search.keywords+'","size":"'+count+'"}',
                '【AI竞投】系统',
                this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                '--',
                this.$store.state.userInfo.account
              )
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      init() {
        this.listLoading = true
        this.hadUpdate = false
        let params = {
          ...this.search,
          page: this.listConfig.page,
          limit: this.listConfig.limit
        }
        api.get_add_list(params).then(res => {
          if(res.data.result_code == 0) {
            if(this.$store.state.userInfo.vip_type == 5 && res.data.data.limit_search_bool) {
              this.notVipShow = true;
              this.commonDialogText = '基础版每天可免费搜索3次。标准版无搜索限制，请联系在线客服开通';
            }
            PinganAna.fire(3150)
            if(this.search.keywords) {
              PinganAna.fire(3146)
              PinganAna.ready(() => {
                PinganAna.userClickLog(
                  '"Position":"搜索"',
                  '{"searchType":"查广告创意","pageName":"'+this.$route.name+'",'+JSON.stringify(params)+'}',
                  '【AI竞投】系统',
                  this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                  '--',
                  this.$store.state.userInfo.account
                )
              })
            }
            this.listData = res.data.data
            this.listLoading = false
            this.listConfig.total = this.listData.cnt
            this.listConfig.real_cnt = this.listData.real_cnt
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
            this.listLoading = false
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.find_advertising {
    .search_box {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-sizing: border-box;
        margin-top: 59px;
        .input_box {
            display: flex;
            align-items: center;
            height: 80px;
            border-bottom: solid 1px #ebeff2;
            padding-left: 23px;
            position: relative;
            .input_shadow {
                width: 713px;
                height: 48px;
                background-color: #f0f5f9;
                border-radius: 6px;
                .el-input {
                    width: 705px;
                    height: 40px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    margin: 4px;
                }
            }
            .check_box {
                margin-left: 30px;
                display: inline-block;
                .tip {
                    display: flex;
                    align-items: center;
                    img {
                        width: 14px;
                        height: 14px;
                        margin-left: 6px;
                        cursor: pointer;
                    }
                }
                .more_info {
                    position: absolute;
                    z-index: 20;
                    .test_triangle_border{
                        width:200px;
                        margin:0 auto 20px;
                        position:relative;
                    }
                    .test_triangle_border a{
                        color:#333;
                        font-weight:bold;
                        text-decoration:none;
                    }
                    .test_triangle_border .popup{
                        width:188px;
                        background:#ffffff;
                        font-size: 14px;
                        line-height: 24px;
                        color: #333333;
                        padding: 13px 9px 13px 9px;
                        position:absolute;
                        left: -8px;
                        top: 10px;
                        box-sizing: border-box;
                        border:1px solid #e8e8e8;
                        border-radius: 6px;
                        box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.75);
                    }
                    .test_triangle_border .popup span{
                        display:block;
                        width:0;
                        height:0;
                        border-width:0 7px 11px;
                        border-style:solid;
                        border-color:transparent transparent #e8e8e8;
                        position:absolute;
                        top:-11px;
                        left: 135px;
                    }
                    .test_triangle_border .popup em{
                        display:block;
                        width:0;
                        height:0;
                        border-width:0 7px 11px;
                        border-style:solid;
                        border-color:transparent transparent #ffffff;
                        position:absolute;
                        top:1px;
                        left:-7px;
                    }
                }
            }
            .empty_input {
                width: 186px;
                height: 44px;
                background-color: #fffaf7;
                box-shadow: 0 4px 10px 0 rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ffd1b5;
                display: flex;
                align-items: center;
                position: absolute;
                top: 60px;
                left: 27px;
                img {
                    height: 14px;
                    width: 14px;
                    margin-left: 16px;
                    margin-right: 10px;
                }
                span {
                    font-size: 14px;
                    color: #333333;
                }
            }
            .search_history {
                width: 661px;
                background-color: #ffffff;
                box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ebeff2;
                position: absolute;
                left: 25px;
                top: 60px;
                max-height: 174px;
                overflow-y: auto;
                z-index: 10;
                &::-webkit-scrollbar {
                    display: none;
                }
                .search_item {
                    height: 32px;
                    font-size: 14px;
                    color: #333333;
                    padding-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: #e8f2fe;
                        color: #1f81f8;
                    }
                }
            }
        }
        .select_line {
            display: flex;
            padding: 23px 20px;
            .select_label {
                display: inline-block;
                color: #999999;
                font-size: 14px;
            }
        }
    }
    .search_list {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-sizing: border-box;
        margin-top: 10px;
        .list_head {
            height: 50px;
            border-bottom: 1px solid #ebeff2;
            display: flex;
            align-items: center;
            position: relative;
            .result {
                font-size: 14px;
                color: #999999;
                margin-left: 18px;
            }
            .count {
                margin: 0;
                color: #ef3819;
            }
            .common {
                position: absolute;
                right: 247px;

            }
            .button {
                position: absolute;
                right: 20px;
            }
            .update {
                position: absolute;
                right: 130px;
                img {
                    transition: .5s ease-in;
                }
            }
            .is-disabled {
                div {
                    color: #999;
                }
            }
        }
        .canClick {
            &:hover {
                color: #1f81f8;
                cursor: pointer;
            }
        }
    }

}
</style>

<style lang="scss">
.find_advertising .el-input-group__append, .el-input-group__prepend  {
    padding: 0;
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
        background-color: #e9f3fd;
    }
    &:active {
        background-color: #f6f9fe;
    }
    .box {
        width: 46px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.find_advertising .el-table em {
    color: #ef3819;
    cursor: pointer;
}
.find_advertising .el-table {
    .cell {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .empty_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            margin-top: 20%;
        }
    }
    a:link {
        color: #1f81f8;
    }
    a:visited {
        color: #999999;
    }
}
.find_advertising .search_list .el-loading-spinner {
    top: 150px;
}

.find_advertising .telephone_dialog {
    width: 435px;
    height: 208px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        padding: 20px;
        font-size: 16px;
        color: #333333;
    }
    .el-dialog__body {
        padding: 20px;
        padding-top: 12px;
        font-size: 14px;
        .line1 {
            color: #333;
            margin-bottom: 10px;
        }
        .time {
            color: #999;
        }
    }
    .el-dialog__footer {
        padding-top: 0;
    }
}
</style>
